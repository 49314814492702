<template>
    <div>
        <b-row>
            <b-col md="6" lg="4" class="mb-3 border-2">
                <h4 class="mb-2">Main background config <tooltip-with-image img="/img/base_items/background.png"/></h4>
                <b-form-group v-if="categories">
                    <label>Background position:</label>
                    <v-select
                        v-model="config.background_position"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="[{id: 0, name: 'Items part'}, {id: 1, name: 'Whole screen'}]"
                    />
                </b-form-group>
                <BackgroundSelector ref="BackgroundSelectorMain" v-model="config.main_background"/>
            </b-col>
            <b-col md="6" lg="4" class="mb-3" v-if="categories">
                <h4 class="mb-2">Category list config  <tooltip-with-image img="/img/base_items/category_list.png"/></h4>
                <b-form-group>
                    <label>Text color: <fa class="ml-1" icon="question-circle" v-b-tooltip="'Set color and opacity.'" /></label>
                    <ColorWithOpacitySelector v-model="config.category_list.text_color"/>
                </b-form-group>
                <b-form-group class="mt-3">
                    <h6>Background:</h6>
                    <BackgroundSelector ref="BackgroundSelectorCategory" v-model="config.category_list.background"/>
                </b-form-group>

            </b-col>
            <b-col md="6" lg="4">
                <h4 class="mb-2">Item cards config  <tooltip-with-image img="/img/base_items/item_cards.png"/></h4>
                <b-form-group>
                    <label for="card_color">Card color:</label>
                    <b-form-input
                        id="card_color"
                        type="color"
                        v-model="config.card.card_color"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="card_text_color">Card text color:</label>
                    <b-form-input
                        id="card_text_color"
                        type="color"
                        v-model="config.card.text_color"
                    />
                </b-form-group>
                <b-form-group>
                    <label for="card_text_position">Card text position:</label>
                    <v-select
                        id="card_text_position"
                        v-model="config.card.text_position"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="cardTextPosition"
                    />
                </b-form-group>
            </b-col>

            <b-col md="6" lg="4" class="mb-3">
                <hr/>
                <h4 class="mb-2">Item details <tooltip-with-image img="/img/base_items/item_details/base_upper.png"/></h4>

                <b-form-group>
                    <label for="item_details_background">Description background: <tooltip-with-image img="/img/base_items/item_details/background.png"/></label>
                    <b-form-input
                        id="item_details_background"
                        type="color"
                        v-model="config.item_details.description_background"
                    />
                </b-form-group>

                <b-form-group>
                    <label for="item_details_action_lane">Action lane background: <tooltip-with-image img="/img/base_items/item_details/action_lane.png"/></label>
                    <b-form-input
                        id="item_details_action_lane"
                        type="color"
                        v-model="config.item_details.action_lane_background"
                    />
                </b-form-group>

                <b-form-group>
                    <label for="item_details_text">Text color: <tooltip-with-image img="/img/base_items/item_details/text_color.png"/></label>
                    <b-form-input
                        id="item_details_text"
                        type="color"
                        v-model="config.item_details.text_color"
                    />
                </b-form-group>

                <h4 class="mb-2">Background  <tooltip-with-image img="/img/base_items/item_details/background_upper.png"/></h4>
                <BackgroundSelector ref="BackgroundSelectorItems" v-model="config.item_details.background"/>
            </b-col>

            <b-col md="6" lg="4" class="mb-3">
                <hr/>
                <h4 class="mb-2">Reservation system config <tooltip-with-image img="/img/base_items/reservation_system/base.png"/></h4>

                <b-form-group>
                    <label>Text color: <fa class="ml-1" icon="question-circle" v-b-tooltip="'Set color and opacity.'" /></label>
                    <ColorWithOpacitySelector v-model="config.reservation_system.text_color"/>
                </b-form-group>
                <b-form-group>
                    <label>Available card color: <tooltip-with-image img="/img/base_items/reservation_system/a_card.png"/></label>
                    <ColorWithOpacitySelector v-model="config.reservation_system.available_card_color"/>
                </b-form-group>
                <b-form-group>
                    <label>Full card color: <tooltip-with-image img="/img/base_items/reservation_system/f_card.png"/></label>
                    <ColorWithOpacitySelector v-model="config.reservation_system.full_card_color"/>
                </b-form-group>
                <b-form-group>
                    <label>Background: <tooltip-with-image img="/img/base_items/reservation_system/background.png"/></label>
                    <BackgroundSelector ref="BackgroundSelectorReservation" v-model="config.reservation_system.background"/>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

    import { BFormInput, BFormGroup, BRow, BCol } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            ColorWithOpacitySelector,
            BackgroundSelector,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            vSelect
        },
        props: {
            categories: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {
                config: {
                    background_position: 0,
                    category_list: {
                        text_color: {},
                        background: {}
                    },
                    main_background: {
                        type: 0,
                        image: '',
                        color: '#ffffff',
                        gradient_type: 0,
                        gradient_direction: 0,
                        colors: []
                    },
                    card: {
                        text_position: 0,
                        text_color: '#ffffff',
                        card_color: '#ffffff'
                    },
                    item_details: {
                        text_color: '#ffffff',
                        description_background: '#ffffff',
                        action_lane_background: '#ffffff',
                        background: {}
                    },
                    reservation_system: {
                        text_color: {
                            color: '#f1f1f1',
                            opacity: 1.0
                        },
                        available_card_color: {
                            color: '#3498db',
                            opacity: 1.0
                        },
                        full_card_color: {
                            color: '#2c3e50',
                            opacity: 0.8
                        },
                        background: {}
                    }
                },

                cardTextPosition: [
                    {
                        id: 0,
                        name: 'Start'
                    },
                    {
                        id: 1,
                        name: 'Center'
                    }
                ]
            }
        },
        methods: {
            getConfig() {
                return this.config
            },
            setConfig(config) {
                this.config = {...this.config, ...config}
            },
            valid(showCategories) {
                if (showCategories && !this.$refs.BackgroundSelectorCategory.valid()) return false
                else if (!this.$refs.BackgroundSelectorMain.valid()) return false
                else if (!this.$refs.BackgroundSelectorItems.valid()) return false
                else if (!this.$refs.BackgroundSelectorReservation.valid()) return false
                return true
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
